import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AboutUsHeroBanner from "../components/AboutUsHeroBanner";
import AboutUsPassionateSection from "../components/AboutUsPassionateSection";

import { useDispatch } from 'react-redux'
import { setHeaderClass } from "../store/slices/general-slice";

declare var $;

const AboutUs = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        if ($("[data-bg-src]").length > 0) {
            $("[data-bg-src]").each(function () {
                var src = $(this).attr("data-bg-src");
                $(this).css("background-image", "url(" + src + ")");
                $(this).removeAttr("data-bg-src").addClass("background-image");
            });
        }
        dispatch(setHeaderClass("header-layout3 bg-white"))
    }, [])

    return <>
        <AboutUsHeroBanner />
        <div className="row" style={{ textAlign: 'center', paddingTop: '60px', fontSize: '22px' }}>
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8 why-area-1" style={{marginTop: 0}}>
                <p className="customText">At Vibra Capital, our focus is on identifying businesses with untapped potential and guiding them towards sustainable growth and industry prominence. Our role extends beyond acquisition; we actively collaborate with these businesses, integrating innovative strategies and providing the direction needed to achieve market success.</p>
            </div>
            <div className="col-lg-3 col-md-2"></div>
        </div>
        <br />
        <br />
        <AboutUsPassionateSection />
    </>
}

export default AboutUs;