import { useEffect } from "react";
import { Link } from "react-router-dom";

declare var $;

const WhatCanWeDoForClientsSection = () => {
    return <div style={{ paddingTop: '100px', backgroundColor: 'white' }} className="feature-area-1 space-bottom">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-12 col-lg-12">
                    <div className="title-area text-center">
                        <h2 className="sec-title">Empowering Your Investment Journey</h2>
                    </div>
                </div>
            </div>
            <div className="row gy-4 align-items-center justify-content-center">
                <div className="col-xl-4 col-md-6">
                    <div className="feature-card">
                        <div className="feature-card-icon">
                            <img src="/img/icon/feature-icon1-1.svg" alt="icon" />
                        </div>
                        <h4 className="feature-card-title">
                            <a>Our Focus</a>
                        </h4>
                        <h6 className="feature-card-title">
                            <a>Targeting Growth, Guiding Success</a>
                        </h6>
                        <p className="feature-card-text" style={{ minHeight: '200px' }}>
                            Vibra Capital seeks to invest in and actively manage businesses with revenues between $5 to $50 million and EBITDA over $1 million. Our focus is on nurturing potential and accelerating growth in promising sectors.
                        </p>
                        <a className="link-btn">
                            <span className="link-effect">
                                <span className="effect-1">LEARN MORE</span>
                                <span className="effect-1">LEARN MORE</span>
                            </span>
                            <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                        </a>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="feature-card">
                        <div className="feature-card-icon">
                            <img src="/img/icon/feature-icon1-2.svg" alt="icon" />
                        </div>
                        <h4 className="feature-card-title">
                            <a >Our Philosophy</a>
                        </h4>
                        <h6 className="feature-card-title">
                            <a >Driven by Vision, Grounded in Values</a>
                        </h6>
                        <p className="feature-card-text" style={{ minHeight: '200px' }}>
                            At Vibra Capital, our philosophy intertwines ethical investment with strategic foresight. We believe in the power of partnership and the transformative impact of responsible, insightful investment practices.
                        </p>
                        <a className="link-btn">
                            <span className="link-effect">
                                <span className="effect-1">LEARN MORE</span>
                                <span className="effect-1">LEARN MORE</span>
                            </span>
                            <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                        </a>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="feature-card">
                        <div className="feature-card-icon">
                            <img src="/img/icon/feature-icon1-3.svg" alt="icon" />
                        </div>
                        <h4 className="feature-card-title">
                            <a >Value for Investors</a>
                        </h4>
                        <h6 className="feature-card-title">
                            <a >Creating Value, Driving Returns</a>
                        </h6>
                        <p className="feature-card-text" style={{ minHeight: '200px' }}>
                            Vibra Capital is more than a financial entity; we are a gateway to opportunities that offer both substantial impact and strong returns. We pride ourselves on creating value that extends beyond financial gains, ensuring lasting success and growth.
                        </p>
                        <a className="link-btn">
                            <span className="link-effect">
                                <span className="effect-1">LEARN MORE</span>
                                <span className="effect-1">LEARN MORE</span>
                            </span>
                            <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default WhatCanWeDoForClientsSection;