const InvestmentFocusFaqs = () => {
    return <div className="faq-area-2 space-bottom overflow-hidden" style={{backgroundColor: 'white'}}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-8">
                    <div className="accordion-area accordion" id="faqAccordion">
                        <div className="accordion-card style2 active">
                            <div className="accordion-header" id="collapse-item-1">
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-1"
                                    aria-expanded="false"
                                    aria-controls="collapse-1"
                                >
                                    Business Profile
                                </button>
                            </div>
                            <div
                                id="collapse-1"
                                className="accordion-collapse collapse show"
                                aria-labelledby="collapse-item-1"
                                data-bs-parent="#faqAccordion"
                            >
                                <div className="accordion-body">
                                    <ul>
                                        <li>
                                            <p className="faq-text">
                                                We target businesses with a strong foothold in their markets, whether they operate in specialized niches or broader sectors, and demonstrate potential for scaling.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                A key criterion is a stable, recurring revenue stream, which may include subscription models, long-term contracts, or other consistent income sources.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                Targeting enterprises with a proven track record of stability and growth, operational for at least seven years.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                Preference for companies rooted in the U.S., with a vision to expand their footprint.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-card style2">
                            <div className="accordion-header" id="collapse-item-2">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-2"
                                    aria-expanded="false"
                                    aria-controls="collapse-2"
                                >
                                    Financial Parameters
                                </button>
                            </div>
                            <div
                                id="collapse-2"
                                className="accordion-collapse collapse "
                                aria-labelledby="collapse-item-2"
                                data-bs-parent="#faqAccordion"
                            >
                                <div className="accordion-body">
                                    <ul>
                                        <li>
                                            <p className="faq-text">
                                                Businesses generating revenues in the range of $5 to $50 million.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                A minimum EBITDA of $1 million, indicative of efficient operations and profitability.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                Seeking companies with a robust EBITDA margin, signifying financial health.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                A history of steady financial growth and a substantial portion of income derived from consistent revenue streams.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                Prioritizing firms with a clear history of revenue expansion year over year.
                                            </p>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-card style2">
                            <div className="accordion-header" id="collapse-item-3">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-3"
                                    aria-expanded="false"
                                    aria-controls="collapse-3"
                                >
                                    Contextual Considerations
                                </button>
                            </div>
                            <div
                                id="collapse-3"
                                className="accordion-collapse collapse "
                                aria-labelledby="collapse-item-3"
                                data-bs-parent="#faqAccordion"
                            >
                                <div className="accordion-body">
                                    <ul>
                                        <li>
                                            <p className="faq-text">
                                                Partnering with business owners seeking strategic exits or liquidity solutions.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                Companies poised for expansion, needing both capital infusion and expertise in management.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                Identifying opportunities where divestiture of non-core segments can unlock value.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                Engaging with businesses where shareholder transition presents a strategic growth opportunity.
                                            </p>
                                        </li>
                                        <li>
                                            <p className="faq-text">
                                                At Vibra Capital, our focus goes beyond mere financial metrics. We delve into the very essence of potential investments, assessing their capacity for innovation, market impact, and sustainable growth. We are on a quest to not just invest but to transform, ushering in a new era of market leadership and success.
                                            </p>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default InvestmentFocusFaqs;