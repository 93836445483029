import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AboutUsHeroBanner from "../components/AboutUsHeroBanner";
import AboutUsPassionateSection from "../components/AboutUsPassionateSection";

import { useDispatch } from 'react-redux'
import { setHeaderClass } from "../store/slices/general-slice";
import CareersHeroBanner from "../components/CareersHeroBanner";
import LetsCreateSomethingGreatSection from "../components/LetsCreateSomethingGreatSection";
import CareersCardsSection from "../components/CareersCardsSection";

declare var $;

const Careers = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        if ($("[data-bg-src]").length > 0) {
            $("[data-bg-src]").each(function () {
                var src = $(this).attr("data-bg-src");
                $(this).css("background-image", "url(" + src + ")");
                $(this).removeAttr("data-bg-src").addClass("background-image");
            });
        }
        dispatch(setHeaderClass("header-layout3 bg-white"))
    }, [])

    return <>
        <CareersHeroBanner />
        <div className="row" style={{ backgroundColor: 'white', paddingTop: '50px', paddingBottom: '50px' }}>
            <div className="col-md-3"></div>
            <div className="col-lg-6 col-md-8 why-area-1" style={{marginTop: 0}}>
                <p className="text-center customText">At Vibra Capital, we are more than a team; we are a community of innovators, thinkers, and leaders dedicated to redefining the landscape of business investment. Our work is about making a real impact — on the businesses we partner with, on the markets we navigate, and on the careers we build.</p>
            </div>
            <div className="col-md-3"></div>
        </div>
        <div className="row" style={{ backgroundColor: 'white', paddingBottom: '50px' }}>
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <h3 className="text-center">Why Work With Us?</h3>
            </div>
            <div className="col-md-3"></div>
        </div>
        <CareersCardsSection />
        <div className="faq-area-2 space-bottom overflow-hidden" style={{ backgroundColor: 'white' }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <div className="accordion-area accordion" id="faqAccordion">
                            <div className="accordion-card accordion-card-custom style2 active">
                                <div className="accordion-header" id="collapse-item-1">
                                    <button
                                        style={{ pointerEvents: 'none' }}
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse-1"
                                        aria-expanded="false"
                                        aria-controls="collapse-1"
                                    >
                                        Current Openings
                                    </button>
                                </div>
                                <div
                                    id="collapse-1"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="collapse-item-1"
                                    data-bs-parent="#faqAccordion"
                                >
                                    <div className="accordion-body">
                                        <ul>
                                            <li>
                                                <p className="faq-text">
                                                    Investment Analyst
                                                </p>
                                            </li>
                                            <li>
                                                <p className="faq-text">
                                                    Business Development Manager
                                                </p>
                                            </li>
                                            <li>
                                                <p className="faq-text">
                                                    Operations Specialist
                                                </p>
                                            </li>
                                            <li>
                                                <p className="faq-text">
                                                    Marketing and Communications Coordinator
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row" style={{ backgroundColor: 'white', paddingBottom: '50px' }}>
            <div className="col-md-3"></div>
            <div className="col-md-6 why-area-1 customText" style={{marginTop: 0}}>
                <h4 className="text-center">How to Apply:</h4>
                <p className="text-center">Are you ready to take your career to the next level? We would love to hear from you. Please submit your resume and a cover letter detailing your interest and suitability for the role to hello@vibracapital.com.</p>
                <p className="text-center">Vibra Capital is an equal-opportunity employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, or protected veteran status and will not be discriminated against on the basis of disability.</p>
            </div>
            <div className="col-md-3"></div>
        </div>
        <LetsCreateSomethingGreatSection />
    </>
}

export default Careers;