import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AboutUsHeroBanner from "../components/AboutUsHeroBanner";
import AboutUsPassionateSection from "../components/AboutUsPassionateSection";

import { useDispatch } from 'react-redux'
import { setHeaderClass } from "../store/slices/general-slice";
import InvestmentFocusHeroBanner from "../components/InvestmentFocusHeroBanner";
import InvestmentFocusFaqs from "../components/InvestmentFocusFaqs";
import LetsCreateSomethingGreatSection from "../components/LetsCreateSomethingGreatSection";

declare var $;

const InvestmentFocus = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        if ($("[data-bg-src]").length > 0) {
            $("[data-bg-src]").each(function () {
                var src = $(this).attr("data-bg-src");
                $(this).css("background-image", "url(" + src + ")");
                $(this).removeAttr("data-bg-src").addClass("background-image");
            });
        }
        dispatch(setHeaderClass("header-layout3 bg-white"))
    }, [])

    return <>
        <InvestmentFocusHeroBanner />
        <div className="row" style={{ textAlign: 'center', paddingTop: '60px', backgroundColor: 'white' }}>
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8 why-area-1" style={{marginTop: 0}}>
                <p className="customText">Vibra Capital is dedicated to forging a new path in the investment landscape, targeting businesses that stand at the threshold of significant growth and innovation. Our investment strategy is designed to uncover hidden gems in the market, transforming them into industry leaders.</p>
            </div>
            <div className="col-lg-3 col-md-2"></div>
        </div>
        <div className="row" style={{backgroundColor: 'white', paddingTop: '20px', paddingBottom: '20px'}}>
            <div className="col-md-12">
                <center><h3>Our Search Criteria</h3></center>
            </div>
        </div>
        <InvestmentFocusFaqs />
        <LetsCreateSomethingGreatSection />
    </>
}

export default InvestmentFocus;