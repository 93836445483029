import { useEffect } from "react";
import { Link } from "react-router-dom";

declare var $;

const Footer = () => {
    return <>
        <footer className="footer-wrapper footer-layout2 bg-white overflow-hidden">
            <div className="container">
                <div className="widget-area space-top" style={{ paddingTop: '50px' }}>
                    <div className="row justify-content-between">
                        <div className="col-md-6 col-xl-5 col-lg-6">
                            <div className="widget widget-newsletter footer-widget">
                                <div className="row">
                                    <div className="col-md-6">
                                    <img src={`/img/logo-black.png`} className="d-none d-lg-inline-block" alt="logo" width="150px" />
                                    <img src={`/img/logo-black.png`} className="d-lg-none" alt="logo" width="105px" />
                                    </div>
                                </div>
                                {/* <br />
                                <br />
                                <h3 className="widget_title">
                                    Sign up as an investor to receive vetted deals
                                </h3> */}
                                {/* <form className="newsletter-form">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="email"
                                            placeholder="Your email here"
                                            required=""
                                        />
                                    </div>
                                    <button type="submit" className="btn">
                                        <img src="assets/img/icon/arrow-left-top.svg" alt="" />
                                    </button>
                                </form>
                                <p>
                                    By signing up to receive emails from Motto, you agree to our
                                    Privacy Policy. We treat your info responsibly.
                                </p> */}
                            </div>
                        </div>
                        <div className="col-md-3 col-xl-2 col-lg-3">
                            <div className="widget widget_nav_menu footer-widget">
                                <h3 className="widget_title">Links</h3>
                                <div className="menu-all-pages-container list-column2">
                                    <ul className="menu">
                                        <li>
                                            <Link to="/about-us"> About</Link>
                                        </li>
                                        <li>
                                            <Link to="/investment-focus">Investment Focus</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/team">Team</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/careers">Careers</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-auto col-lg-4">
                            <div className="widget footer-widget widget-contact">
                                <h3 className="widget_title">Contact</h3>
                                <ul className="contact-info-list">
                                    <li>
                                        1 Glenlake Pkwy NE, <br /> Atlanta, GA 30328
                                    </li>
                                    <li>
                                        <a href="tel:4047063817">+404-706-3817</a>
                                        <br />
                                        <a href="mailto:hello@vibracapital.com">
                                            hello@vibracapital.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright-wrap">
                    <div className="row gy-3 justify-content-between align-items-center">
                        <div className="col-md-12 text-center">
                            <p className="copyright-text">
                                Copyright © 2023-2024
                                {/* <a href="https://themeforest.net/user/themegenix">ThemeGenix</a> */}
                            </p>
                        </div>
                        {/* <div className="col-md-6 align-self-center">
                            <div className="social-btn style3 justify-content-md-end">
                                <a href="https://www.facebook.com/">
                                    <span className="link-effect">
                                        <span className="effect-1">
                                            <i className="fab fa-facebook" />
                                        </span>
                                        <span className="effect-1">
                                            <i className="fab fa-facebook" />
                                        </span>
                                    </span>
                                </a>
                                <a href="https://instagram.com/">
                                    <span className="link-effect">
                                        <span className="effect-1">
                                            <i className="fab fa-instagram" />
                                        </span>
                                        <span className="effect-1">
                                            <i className="fab fa-instagram" />
                                        </span>
                                    </span>
                                </a>
                                <a href="https://twitter.com/">
                                    <span className="link-effect">
                                        <span className="effect-1">
                                            <i className="fab fa-twitter" />
                                        </span>
                                        <span className="effect-1">
                                            <i className="fab fa-twitter" />
                                        </span>
                                    </span>
                                </a>
                                <a href="https://dribbble.com/">
                                    <span className="link-effect">
                                        <span className="effect-1">
                                            <i className="fab fa-dribbble" />
                                        </span>
                                        <span className="effect-1">
                                            <i className="fab fa-dribbble" />
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
        <div className="scroll-top">
            <svg
                className="progress-circle svg-content"
                width="100%"
                height="100%"
                viewBox="-1 -1 102 102"
            >
                <path
                    d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                    style={{
                        transition: "stroke-dashoffset 10ms linear 0s",
                        strokeDasharray: "307.919, 307.919",
                        strokeDashoffset: "307.919"
                    }}
                />
            </svg>
        </div>
    </>
}

export default Footer;