const ContactAddressSection = () => {
    return <div className="feature-area-1 space">
        <div className="container">
            <div className="row gy-4 align-items-center justify-content-center">
                <div className="col-xl-4 col-md-6">
                    <div className="feature-card">
                        <div className="feature-card-icon">
                            <img src="/img/icon/location-pin-alt.svg" alt="icon" />
                        </div>
                        <div className="feature-card-details" style={{height: '150px'}}>
                            <h4 className="feature-card-title">
                                <a href="project.html">Office</a>
                            </h4>
                            <p className="feature-card-text mb-0">
                                1 Glenlake Pkwy NE,
                            </p>
                            <p className="feature-card-text">Atlanta, GA 30328 </p>
                            <a href="https://maps.google.com" className="link-btn">
                                <span className="link-effect">
                                    <span className="effect-1">Get direction</span>
                                    <span className="effect-1">Get direction</span>
                                </span>
                                <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="feature-card">
                        <div className="feature-card-icon">
                            <img src="/img/icon/speech-bubble.svg" alt="icon" />
                        </div>
                        <div className="feature-card-details" style={{height: '150px'}}>
                            <h4 className="feature-card-title">
                                <a href="project.html">Email Address</a>
                            </h4>
                            <p className="feature-card-text mb-0">hello@vibracapital.com</p>
                            <br />
                            {/* <p className="feature-card-text">hello@vibracapital.com</p> */}
                            <a href="mailto:hello@vibracapital.com" className="link-btn">
                                <span className="link-effect">
                                    <span className="effect-1">Send message</span>
                                    <span className="effect-1">Send message</span>
                                </span>
                                <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="feature-card">
                        <div className="feature-card-icon">
                            <img src="/img/icon/phone.svg" alt="icon" />
                        </div>
                        <div className="feature-card-details" style={{height: '150px'}}>
                            <h4 className="feature-card-title">
                                <a href="project.html">Phone Number</a>
                            </h4>
                            <p className="feature-card-text mb-0">+404-706-3817 </p>
                            <br />
                            {/* <p className="feature-card-text">+404-706-3817 </p> */}
                            <a href="tel:4047063817" className="link-btn">
                                <span className="link-effect">
                                    <span className="effect-1">Call anytime</span>
                                    <span className="effect-1">Call anytime</span>
                                </span>
                                <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ContactAddressSection;