import { useEffect } from "react";

declare var $;

const ContactMapSection = () => {

    useEffect(() => {

        $.fn.shapeMockup = function () {
            var $shape = $(this);
            $shape.each(function () {
                var $currentShape = $(this),
                    shapeTop = $currentShape.data("top"),
                    shapeRight = $currentShape.data("right"),
                    shapeBottom = $currentShape.data("bottom"),
                    shapeLeft = $currentShape.data("left");
                $currentShape
                    .css({
                        top: shapeTop,
                        right: shapeRight,
                        bottom: shapeBottom,
                        left: shapeLeft,
                    })
                    .removeAttr("data-top")
                    .removeAttr("data-right")
                    .removeAttr("data-bottom")
                    .removeAttr("data-left")
                    .parent()
                    .addClass("shape-mockup-wrap");
            });
        };

        if ($(".shape-mockup")) {
            $(".shape-mockup").shapeMockup();
        }
    }, [])
    return <div className="contact-area-1 space bg-theme">
        <div
            className="contact-map shape-mockup wow img-custom-anim-left animated"
            data-wow-duration="1.5s"
            data-wow-delay="0.2s"
            data-left={0}
            data-top="-100px"
            data-bottom="140px"
        >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3310.2228585491657!2d-84.36261722461494!3d33.93539577320119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50c030201d60f%3A0x96d7a06263246d65!2s1%20Glenlake%20Pkwy%20NE%2C%20Sandy%20Springs%2C%20GA%2030328%2C%20USA!5e0!3m2!1sen!2s!4v1703352704748!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className="container">
            <div className="row align-items-center justify-content-end">
                <div className="col-lg-6">
                    <div className="contact-form-wrap">
                        <div className="title-area mb-30">
                            <h2 className="sec-title">Get in touch</h2>
                            {/* <p>
                                Get in touch
                            </p> */}
                        </div>
                        <form
                            action="mail.php"
                            method="POST"
                            className="contact-form ajax-contact"
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control style-border"
                                            name="name"
                                            id="name"
                                            placeholder="Full name*"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control style-border"
                                            name="email"
                                            id="email"
                                            placeholder="Email address*"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control style-border"
                                            name="website"
                                            id="website"
                                            placeholder="Website link"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <textarea
                                            name="message"
                                            placeholder="How Can We Help You*"
                                            id="contactForm"
                                            className="form-control style-border"
                                            defaultValue={""}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-btn col-12">
                                <button type="submit" className="btn mt-20">
                                    <span className="link-effect">
                                        <span className="effect-1">SEND MESSAGE</span>
                                        <span className="effect-1">SEND MESSAGE</span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ContactMapSection;