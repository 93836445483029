import { useEffect } from "react";
import { Link } from "react-router-dom";

declare var $;

const PopupSearchBar = () => {
    return <div className="popup-search-box">
        <button className="searchClose">
            <img src="assets/img/icon/close.svg" alt="img" />
        </button>
        <form action="#">
            <input type="text" placeholder="Search Here.." />
            <button type="submit">
                <img src="assets/img/icon/search-white.svg" alt="img" />
            </button>
        </form>
    </div>

}

export default PopupSearchBar;