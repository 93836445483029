import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeHeroBanner from '../components/HomeHeroBanner'
import HomeAboutSection from '../components/HomeAboutSection'
import WhatCanWeDoForClientsSection from '../components/WhatCanWeDoForClientsSection'
import LetsCreateSomethingGreatSection from "../components/LetsCreateSomethingGreatSection";

import { useDispatch } from 'react-redux'
import { setHeaderClass } from "../store/slices/general-slice";

const Home = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeaderClass("header-layout2"))
        document.body.classList.add("yellowBg")

        return () => {
            document.body.classList.remove("yellowBg")
        }
    }, [])

    return <>
        <HomeHeroBanner />
        <HomeAboutSection />
        <WhatCanWeDoForClientsSection />
        <LetsCreateSomethingGreatSection />
    </>
}

export default Home;