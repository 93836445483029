const AboutUsHeroBanner = () => {
    return <div
        className="breadcumb-wrapper"
        data-bg-src="/img/about-us.png"
    >
        <div className="container">
            <div className="breadcumb-content">
                <h1 className="breadcumb-title">We are Vibra(nt)</h1>
            </div>
        </div>
    </div>
}

export default AboutUsHeroBanner;