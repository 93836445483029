import React, { lazy, Suspense, memo } from 'react'
import { useSelector } from 'react-redux'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import PopupSearchBar from '../components/PopupSearchBar'

const Layouts = ({ children }) => {

    return <>
        <PopupSearchBar />
        <Sidebar />
        <Navbar />
        {children}
        <Footer />
    </>
}

export default memo(Layouts)