import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

declare var $;

const Navbar = () => {

  const headerClass = useSelector(state => state.general.headerClass);

  useEffect(() => {
    $.fn.mobilemenu = function (options) {
      var opt = $.extend(
        {
          menuToggleBtn: ".menu-toggle",
          bodyToggleClass: "body-visible",
          subMenuClass: "submenu-class",
          subMenuParent: "submenu-item-has-children",
          subMenuParentToggle: "active-class",
          meanExpandClass: "mean-expand-class",
          appendElement: '<span class="mean-expand-class"></span>',
          subMenuToggleClass: "menu-open",
          toggleSpeed: 400,
        },
        options
      );

      return this.each(function () {
        var menu = $(this);

        function menuToggle() {
          menu.toggleClass(opt.bodyToggleClass);

          var subMenu = "." + opt.subMenuClass;
          $(subMenu).each(function () {
            if ($(this).hasClass(opt.subMenuToggleClass)) {
              $(this).removeClass(opt.subMenuToggleClass);
              $(this).css("display", "none");
              $(this).parent().removeClass(opt.subMenuParentToggle);
            }
          });
        }

        menu.find("li").each(function () {
          var submenu = $(this).find("ul");
          submenu.addClass(opt.subMenuClass);
          submenu.css("display", "none");
          submenu.parent().addClass(opt.subMenuParent);
          submenu.prev("a").append(opt.appendElement);
          submenu.next("a").append(opt.appendElement);
        });

        function toggleDropDown($element) {
          var $parent = $($element).parent();
          var $siblings = $parent.siblings();

          $siblings.removeClass(opt.subMenuParentToggle);
          $siblings.find("ul").slideUp(opt.toggleSpeed).removeClass(opt.subMenuToggleClass);

          $parent.toggleClass(opt.subMenuParentToggle);
          $($element).next("ul").slideToggle(opt.toggleSpeed).toggleClass(opt.subMenuToggleClass);
        }

        var expandToggler = "." + opt.meanExpandClass;
        $(expandToggler).each(function () {
          $(this).on("click", function (e) {
            e.preventDefault();
            toggleDropDown($(this).parent());
          });
        });

        $(opt.menuToggleBtn).each(function () {
          $(this).on("click", function () {
            menuToggle();
          });
        });

        menu.on("click", function (e) {
          e.stopPropagation();
          menuToggle();
        });

        menu.find("div").on("click", function (e) {
          e.stopPropagation();
        });
      });
    };
    $(".mobile-menu-wrapper").mobilemenu();
  }, [])

  return <>
    <>
      <div className="mobile-menu-wrapper">
        <div className="mobile-menu-area">
          <button className="menu-toggle">
            <i className="fas fa-times" />
          </button>
          <div className="mobile-logo">
            <Link to="/">
              <img src="/img/logo-black.png" alt="Ovation" width="120px" />
            </Link>
          </div>
          <div className="mobile-menu">
            <ul>
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/about-us">ABOUT</Link>
              </li>
              <li>
                <Link to="/investment-focus">INVESTMENT FOCUS</Link>
              </li>
              {/* <li>
                <Link to="/team">TEAM</Link>
              </li> */}
              <li>
                <Link to="/careers">CAREERS</Link>
              </li>
              <li>
                <Link to="/contact">CONTACT</Link>
              </li>
            </ul>
          </div>
          <div className="sidebar-wrap">
            <h6>1 Glenlake Pkwy NE,</h6>
            <h6>Atlanta, GA 30328</h6>
          </div>
          <div className="sidebar-wrap">
            <h6>
              <a href="tel:4047063817">+404-706-3817 </a>
            </h6>
            <h6>
              <a href="mailto:hello@vibracapital.com">hello@vibracapital.com</a>
            </h6>
          </div>
          {/* <div className="social-btn style3">
            <a>
              <span className="link-effect">
                <span className="effect-1">
                  <i className="fab fa-facebook" />
                </span>
                <span className="effect-1">
                  <i className="fab fa-facebook" />
                </span>
              </span>
            </a>
            <a>
              <span className="link-effect">
                <span className="effect-1">
                  <i className="fab fa-instagram" />
                </span>
                <span className="effect-1">
                  <i className="fab fa-instagram" />
                </span>
              </span>
            </a>
            <a>
              <span className="link-effect">
                <span className="effect-1">
                  <i className="fab fa-twitter" />
                </span>
                <span className="effect-1">
                  <i className="fab fa-twitter" />
                </span>
              </span>
            </a>
            <a>
              <span className="link-effect">
                <span className="effect-1">
                  <i className="fab fa-dribbble" />
                </span>
                <span className="effect-1">
                  <i className="fab fa-dribbble" />
                </span>
              </span>
            </a>
          </div> */}
        </div>
      </div>
      <header className={`nav-header ${headerClass}`}>
        <div className="sticky-wrapper">
          {/* Main Menu Area */}
          <div className="menu-area">
            <div className="container-fluid">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  {/* SHOW ON SMALL SCREEN */}
                  <div className="header-logo d-lg-none" style={{ paddingTop: '25px', paddingLeft: '20px', paddingBottom: '25px' }}>
                    <Link to="/">
                      <img src={`/img/logo${headerClass === 'header-layout2' ? '-white' : '-black'}.png`} alt="logo" width="105px" />
                    </Link>
                  </div>
                  {/* SHOW ON LARGE SCREEN */}
                  <div className="header-logo d-none d-lg-inline-block" style={{ paddingTop: '25px', paddingLeft: '70px', paddingBottom: '25px' }}>
                    <Link to="/">
                      <img src={`/img/logo${headerClass === 'header-layout2' ? '-white' : '-black'}.png`} alt="logo" width="150px" />
                    </Link>
                  </div>
                </div>
                <div className="col-auto ms-auto">
                  <nav className="main-menu d-none d-lg-inline-block">
                    <ul>
                      <li className="active menu-item-has-children">
                        <Link to="/">
                          <span className="link-effect">
                            <span className="effect-1">HOME</span>
                            <span className="effect-1">HOME</span>
                          </span>
                        </Link>
                      </li>
                      <li className="active menu-item-has-children">
                        <Link to="/about-us">
                          <span className="link-effect">
                            <span className="effect-1">ABOUT</span>
                            <span className="effect-1">ABOUT</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/investment-focus">
                          <span className="link-effect">
                            <span className="effect-1">INVESTMENT FOCUS</span>
                            <span className="effect-1">INVESTMENT FOCUS</span>
                          </span>
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/team">
                          <span className="link-effect">
                            <span className="effect-1">TEAM</span>
                            <span className="effect-1">TEAM</span>
                          </span>
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/careers">
                          <span className="link-effect">
                            <span className="effect-1">CAREERS</span>
                            <span className="effect-1">CAREERS</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact">
                          <span className="link-effect">
                            <span className="effect-1">CONTACT</span>
                            <span className="effect-1">CONTACT</span>
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                  <div className="navbar-right d-inline-flex d-lg-none">
                    <button type="button" className="menu-toggle sidebar-btn">
                      <span className="line" />
                      <span className="line" />
                      <span className="line" />
                    </button>
                  </div>
                </div>
                {/* <div className="col-auto d-none d-lg-block">
                  <div className="header-button">
                    <button type="button" className="search-btn searchBoxToggler">
                      <img src={`/img/icon/search${headerClass === 'header-layout2' ? '-white' : ''}.svg`} alt="icon" />
                      <span className="link-effect">
                        <span className="effect-1">SEARCH</span>
                        <span className="effect-1">SEARCH</span>
                      </span>
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>

  </>
}

export default Navbar;