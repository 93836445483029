import { useEffect } from "react";
import { Link } from "react-router-dom";

declare var WOW;

const HomeHeroBanner = () => {

    useEffect(() => {
        new WOW().init();
    }, [])

    return <div className="hero-wrapper hero-2" id="hero">
        <div
            className="hero-2-thumb wow img-custom-anim-right animated"
            data-wow-duration="1.5s"
            // data-wow-delay="0.2s"
        >
            <img src="/img/hero-banner.png" alt="img" />
        </div>
        <div className="container">
            <div className="hero-style2">
                <div className="row">
                    <div className="col-lg-9">
                        <h1 className="hero-title wow img-custom-anim-right animated text-white">
                            Impact-driven acquisitions for scale
                        </h1>
                        <h1 className="hero-title wow img-custom-anim-left animated text-white">
                            {/* Business Acquisitions Built for Tomorrow */}
                        </h1>
                    </div>
                    <div className="col-xxl-4 col-xl-5 col-lg-6">
                        <p className="hero-text wow img-custom-anim-left animated text-white">
                            Vibra Capital expertly identifies, acquires, and manages promising private businesses for strategic direction and growth.
                        </p>
                        <div className="btn-group fade_left">
                            <a
                                className="btn style2 wow img-custom-anim-left animated"
                            >
                                <span className="link-effect">
                                    <span className="effect-1">LEARN MORE</span>
                                    <span className="effect-1">LEARN MORE</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


}

export default HomeHeroBanner;