const CareersHeroBanner = () => {
    return <div
        className="breadcumb-wrapper"
        data-bg-src="/img/careers.png"
    >
        <div className="container">
            <div className="breadcumb-content">
                <h1 className="breadcumb-title">Careers</h1>
            </div>
        </div>
    </div>
}

export default CareersHeroBanner;