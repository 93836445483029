const ContactHeroBanner = () => {
    return <div
        className="breadcumb-wrapper "
        data-bg-src="/img/contact.png"
    >
        <div className="container">
            <div className="breadcumb-content">
                <h1 className="breadcumb-title">Contact</h1>
            </div>
        </div>
    </div>
}

export default ContactHeroBanner;