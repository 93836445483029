import { useEffect } from "react";
import { Link } from "react-router-dom";

declare var $;

const Sidebar = () => {
    return <div className="sidemenu-wrapper">
    <div className="sidemenu-content">
      <button className="closeButton sideMenuCls">
        <img src="assets/img/icon/close.svg" alt="icon" />
      </button>
      <div className="widget footer-widget">
        <div className="widget-about">
          <div className="footer-logo">
            <Link to="/">
              <img src="/img/logo-white.svg" alt="Ovation" />
            </Link>
          </div>
          <p className="about-text">
            {/* We are digital agency that helps businesses develop immersive and
            engaging */}
          </p>
          <div className="sidebar-wrap">
            <h6>1 Glenlake Pkwy NE,</h6>
            <h6>Atlanta, GA 30328</h6>
          </div>
          <div className="sidebar-wrap">
            <h6>
              <a href="tel:4047063817">+404-706-3817</a>
            </h6>
            <h6>
              <a href="mailto:hello@vibracapital.com">hello@vibracapital.com</a>
            </h6>
          </div>
          <div className="social-btn style2">
            <a>
              <span className="link-effect">
                <span className="effect-1">
                  <i className="fab fa-facebook" />
                </span>
                <span className="effect-1">
                  <i className="fab fa-facebook" />
                </span>
              </span>
            </a>
            <a>
              <span className="link-effect">
                <span className="effect-1">
                  <i className="fab fa-instagram" />
                </span>
                <span className="effect-1">
                  <i className="fab fa-instagram" />
                </span>
              </span>
            </a>
            <a>
              <span className="link-effect">
                <span className="effect-1">
                  <i className="fab fa-twitter" />
                </span>
                <span className="effect-1">
                  <i className="fab fa-twitter" />
                </span>
              </span>
            </a>
            <a>
              <span className="link-effect">
                <span className="effect-1">
                  <i className="fab fa-dribbble" />
                </span>
                <span className="effect-1">
                  <i className="fab fa-dribbble" />
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <button className="chat-btn">Let’s Talk with us</button>
    </div>
  </div>
}

export default Sidebar;