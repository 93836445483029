const InvestmentFocusHeroBanner = () => {
    return <div
        className="breadcumb-wrapper "
        data-bg-src="/img/our-focus.png"
    >
        <div className="container">
            <div className="breadcumb-content">
                <h1 className="breadcumb-title">Investment Focus</h1>
            </div>
        </div>
    </div>

}

export default InvestmentFocusHeroBanner;