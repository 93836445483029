import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const initialState = {
	loading: false,
	pageHeaderTitle: null,
	contentLoaded: false,
	headerClass: 'header-layout2'
}

export const setPageHeaderTitle = createAsyncThunk('pageHeaderTitle', async( data ) => {})
export const setContentLoaded = createAsyncThunk('contentLoaded', async( data ) => {})
export const setHeaderClass = createAsyncThunk('headerClass', async( data ) => {})

export const generalSlice = createSlice({
	name: 'general',
	initialState,
	extraReducers: (builder) => {
		builder
        .addCase(setPageHeaderTitle.fulfilled, (state, action) => {
			state.pageHeaderTitle = action.meta.arg
        })
        .addCase(setContentLoaded.fulfilled, (state, action) => {
			state.contentLoaded = action.meta.arg
        })
        .addCase(setHeaderClass.fulfilled, (state, action) => {
			state.headerClass = action.meta.arg
        })
	},
})

export default generalSlice.reducer