const TeamMemberDetail = (props) => {
    return <div className="team-details-page-area space">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-xl-5 col-lg-6">
          <div className="team-inner-thumb mb-lg-0 mb-40 d-none d-lg-inline-block" style={{textAlign: 'right'}}>
            <img
              className="w-100"
              src={props.image}
              style={{maxWidth: "80%"}}
              alt="img"
            />
          </div>
          <div className="team-inner-thumb mb-lg-0 mb-40 d-lg-none" style={{textAlign: 'center'}}>
            <img
              className="w-100"
              src={props.image}
              alt="img"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="team-details-about-info mb-0">
            <h2 className="sec-title mb-3">{props.name}</h2>
            <p className="sec-text mt-30" dangerouslySetInnerHTML={{__html: props.content}}></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
}

export default TeamMemberDetail;