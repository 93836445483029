import { useEffect } from "react";

declare var $;

const AboutUsPassionateSection = () => {

    useEffect(() => {
        $.fn.shapeMockup = function () {
            var $shape = $(this);
            $shape.each(function () {
                var $currentShape = $(this),
                    shapeTop = $currentShape.data("top"),
                    shapeRight = $currentShape.data("right"),
                    shapeBottom = $currentShape.data("bottom"),
                    shapeLeft = $currentShape.data("left");
                $currentShape
                    .css({
                        top: shapeTop,
                        right: shapeRight,
                        bottom: shapeBottom,
                        left: shapeLeft,
                    })
                    .removeAttr("data-top")
                    .removeAttr("data-right")
                    .removeAttr("data-bottom")
                    .removeAttr("data-left")
                    .parent()
                    .addClass("shape-mockup-wrap");
            });
        };

        if ($(".shape-mockup")) {
            $(".shape-mockup").shapeMockup();
        }
    }, [])

    return <div className="why-area-1 space bg-theme">
        <div
            className="why-img-1-1 shape-mockup wow img-custom-anim-right animated"
            data-wow-duration="1.5s"
            data-wow-delay="0.2s"
            data-right={0}
            data-top="-100px"

            data-bottom="140px"
        >
            <img src="/img/our-philosophy.png" alt="img" />
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <h4 style={{fontSize: '50px'}}>Our Philosophy</h4>
                    <p>
                        Our philosophy emphasizes a holistic approach in our acquisition strategy. We seek businesses that not only meet our financial benchmarks but also resonate with our commitment to driving industry innovation and positive societal influence. This comprehensive perspective ensures that our involvement goes beyond financial metrics, contributing meaningfully to both the business landscape and the wider community.
                    </p>
                    <h4 style={{fontSize: '50px'}} className="mt-35">Value for Investors</h4>
                    <p className="mb-n1">
                        Investing with Vibra Capital means embarking on a transformative journey. Our investors are integral to the process of evolving promising companies into industry leaders. Leveraging our strategic management and operational expertise, each acquisition is a deliberate step in constructing a portfolio of thriving, sustainable businesses. Our commitment is to deliver robust returns and enduring value, ensuring that every investment realizes its full potential beyond mere financial metrics.
                    </p>
                    <br />
                    <p className="mb-n1">
                        Join us in shaping the landscape of business, where each acquisition is a step towards a brighter, more innovative future.
                    </p>
                </div>
            </div>
        </div>
    </div>
}

export default AboutUsPassionateSection;