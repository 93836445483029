import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AboutUsHeroBanner from "../components/AboutUsHeroBanner";
import AboutUsPassionateSection from "../components/AboutUsPassionateSection";

import { useDispatch } from 'react-redux'
import { setHeaderClass } from "../store/slices/general-slice";
import TeamHeroBanner from "../components/TeamHeroBanner";
import LetsCreateSomethingGreatSection from "../components/LetsCreateSomethingGreatSection";
import TeamMemberDetail from "../components/TeamMemberDetail";

declare var $;

const Team = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        if ($("[data-bg-src]").length > 0) {
            $("[data-bg-src]").each(function () {
                var src = $(this).attr("data-bg-src");
                $(this).css("background-image", "url(" + src + ")");
                $(this).removeAttr("data-bg-src").addClass("background-image");
            });
        }
        dispatch(setHeaderClass("header-layout3 bg-white"))
    }, [])

    return <>
        <TeamHeroBanner />
        <TeamMemberDetail image="/img/udoka.png" name="Udoka Uzoka" content="
            <p>Udoka Uzoka is a Managing Partner at Vibra Capital, where he applies his extensive experience in tech-driven transformations to identify and grow promising businesses. His expertise in leading SaaS startups to profitability and navigating the complex software industry is a cornerstone of Vibra Capital's success.</p>
            <p>Udoka's strong academic foundation laid the groundwork for his career. He holds a degree in Physics from North Carolina Central University, a Bachelor's in Electrical Engineering from Duke University, and a Master's in Engineering Management from Johns Hopkins University. His education has provided him with a deep understanding of technology and its applications in the business world.</p>
            <p>As the founder of Intelia, a premier software company and NASDAQ's Governance solution’s implementation partner, Udoka has honed his skills in strategic leadership and technological innovation. His entrepreneurial journey includes co-founding Cubix.co, a New York-based application development firm. Under his guidance, Cubix.co developed widely-used applications and served high-profile clients like Walmart, SONY, and Canon. Additionally, he established Kinetic Base, focusing on innovative banking solutions, including a patent-pending technology for computerized debit cards.</p>
            <p>Before stepping into the entrepreneurial world, Udoka was a key contributor to DuPont USA's engineering team, working on improvements to their flagship product, Kevlar.</p>
        " />
        <hr />
        <TeamMemberDetail image="/img/jason.png" name="Jason Hines" content="
            <p>Jason Hines is a Managing Partner at Vibra Capital, known for his strategic acumen and deep understanding of global markets. An MBA graduate from MIT Sloan and an alumnus of Duke University, Jason's educational background lays a solid foundation for his business insights.</p>
            <p>Before joining Vibra Capital, Jason made significant strides at Boston Consulting Group (BCG), where he led advisory projects for major corporations, focusing on market penetration strategies and operational improvements. His ability to translate complex market dynamics into actionable strategies marked his tenure at BCG.</p>
            <p>At Vibra Capital, Jason combines his consulting experience with his keen financial expertise to drive investment decisions. His approach is grounded in thorough market analysis, focusing on creating sustainable value and long-term growth for the firm's portfolio companies. Jason’s leadership in structuring impactful investment deals has been a key factor in the firm’s success.</p>
            <p>In addition to his professional pursuits, Jason is actively involved in mentoring young professionals and often shares his insights on financial strategies and market dynamics at industry conferences and educational institutions.</p>
            
        " />
        <LetsCreateSomethingGreatSection />
    </>
}

export default Team;