// import './App.css';
import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import store from './store';
import Layouts from './layouts'
import history from './history'
import Home from './home'
import AboutUs from './about-us';
import ScrollToTop from './ScrollToTop';
import InvestmentFocus from './investment-focus';
import Team from './team';
import Careers from './careers';
import Contact from './contact';

const routes = [
  {
    path: '/',
    title: 'home',
    component: Home
  },
  {
    path: '/home',
    title: 'home',
    component: Home
  },
  {
    path: '/about-us',
    title: 'about-us',
    component: AboutUs
  },
  {
    path: '/investment-focus',
    title: 'investment-focus',
    component: InvestmentFocus
  },
  // {
  //   path: '/team',
  //   title: 'team',
  //   component: Team
  // },
  {
    path: '/careers',
    title: 'careers',
    component: Careers
  },
  {
    path: '/contact',
    title: 'contact',
    component: Contact
  }
]

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ScrollToTop />
          <Layouts>
            <Routes>
              {routes.map((route, index) => (
                <Route
                  path={route.path}
                  key={'route-key-' + index}
                  element={<route.component />}
                />
              ))}
            </Routes>
          </Layouts>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
