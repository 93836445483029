import { useEffect } from "react";
import { Link } from "react-router-dom";

const LetsCreateSomethingGreatSection = () => {
    return <div className="cta-area-1 overflow-hidden bg-theme space">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-12 col-lg-12">
                    <div className="title-area text-center mb-0">
                        <h2 className="sec-title">Explore Partnership Opportunities</h2>
                        <p className="sec-text mt-30 mb-40">
                            Discover how you can collaborate with Vibra Capital as an investor or business ready for its next growth phase.
                        </p>
                        <div className="btn-group justify-content-center">
                            <a className="btn mt-0">
                                <span className="link-effect">
                                    <span className="effect-1">GET IN TOUCH</span>
                                    <span className="effect-1">GET IN TOUCH</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default LetsCreateSomethingGreatSection;