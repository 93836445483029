import { useEffect } from "react";
import { Link } from "react-router-dom";

declare var $;

const HomeAboutSection = () => {

    useEffect(() => {

        $.fn.shapeMockup = function () {
            var $shape = $(this);
            $shape.each(function () {
                var $currentShape = $(this),
                    shapeTop = $currentShape.data("top"),
                    shapeRight = $currentShape.data("right"),
                    shapeBottom = $currentShape.data("bottom"),
                    shapeLeft = $currentShape.data("left");
                $currentShape
                    .css({
                        top: shapeTop,
                        right: shapeRight,
                        bottom: shapeBottom,
                        left: shapeLeft,
                    })
                    .removeAttr("data-top")
                    .removeAttr("data-right")
                    .removeAttr("data-bottom")
                    .removeAttr("data-left")
                    .parent()
                    .addClass("shape-mockup-wrap");
            });
        };

        if ($(".shape-mockup")) {
            $(".shape-mockup").shapeMockup();
        }
    }, [])

    return <div className="about-area-1 space bg-theme">
        <div
            className="about-img-1-1 shape-mockup img-custom-anim-left wow animated"
            data-left={0}
            data-top="-100px"
            data-bottom="140px"
            data-wow-duration="1.5s"
            data-wow-delay="0.1s"
        >
            <img src="/img/accelerate-growth.png" alt="img" />
        </div>
        <div className="container">
            <div className="row align-items-center justify-content-end">
                <div className="col-lg-6">
                    <div className="overflow-hidden">
                        <div className="about-content-wrap ">
                            <div className="title-area mb-0">
                                <h2 className="sec-title">
                                    Driving Growth with Business Acquisitions
                                </h2>
                                <p className="sec-text mt-35">
                                    Our partners value our deep commitment to their long-term success. At Vibra Capital, we specialize in identifying promising businesses for acquisition and skillfully steering them towards sustained growth and profitability. We're not just investors; we're hands-on partners dedicated to nurturing and managing businesses to unlock their full potential.
                                </p>
                                <p className="sec-text mt-30">
                                    In our acquisitions, we employ thorough management expertise to ensure each business we engage with not only grows but also thrives in its competitive landscape.
                                </p>
                                <p className="sec-text mt-30">
                                    Experience the transformative power of partnership with Vibra Capital.
                                </p>
                                <div className="btn-wrap mt-50">
                                    <Link to="/about-us" className="link-btn">
                                        <span className="link-effect">
                                            <span className="effect-1">ABOUT US</span>
                                            <span className="effect-1">ABOUT US</span>
                                        </span>
                                        <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default HomeAboutSection;