import { useEffect } from "react";
import { Link } from "react-router-dom";

declare var $;

const CareersCardsSection = () => {
    return <div style={{ backgroundColor: 'white' }} className="feature-area-1 space-bottom">
        <div className="container">
            <div className="row gy-4 align-items-center justify-content-center">
                <div className="col-xl-6 col-md-6">
                    <div className="feature-card">
                        <div className="feature-card-icon">
                            <img src="/img/icon/feature-icon1-1.svg" alt="icon" />
                        </div>
                        <h4 className="feature-card-title">
                            <a href="project.html">Culture of Excellence and Innovation</a>
                        </h4>
                        <p className="feature-card-text" style={{ minHeight: '0px' }}>
                            At Vibra Capital, you’ll be part of an environment that values excellence and encourages innovation. We believe in pushing boundaries, thinking ahead, and turning challenges into opportunities.
                        </p>
                        <a className="link-btn">
                            <span className="link-effect">
                                <span className="effect-1">LEARN MORE</span>
                                <span className="effect-1">LEARN MORE</span>
                            </span>
                            <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                        </a>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6">
                    <div className="feature-card">
                        <div className="feature-card-icon">
                            <img src="/img/icon/feature-icon1-2.svg" alt="icon" />
                        </div>
                        <h4 className="feature-card-title">
                            <a href="project.html">Diverse and Inclusive Workplace</a>
                        </h4>
                        <p className="feature-card-text" style={{ minHeight: '0px' }}>
                            Diversity is not just a buzzword for us; it’s a cornerstone of our success. We are committed to creating an inclusive environment where a variety of perspectives are welcomed and respected.
                        </p>
                        <a href="project-details.html" className="link-btn">
                            <span className="link-effect">
                                <span className="effect-1">LEARN MORE</span>
                                <span className="effect-1">LEARN MORE</span>
                            </span>
                            <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                        </a>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6">
                    <div className="feature-card">
                        <div className="feature-card-icon">
                            <img src="/img/icon/feature-icon1-3.svg" alt="icon" />
                        </div>
                        <h4 className="feature-card-title">
                            <a href="project.html">Professional Growth and Development</a>
                        </h4>
                        <p className="feature-card-text" style={{ minHeight: '0px' }}>
                            Your growth is our priority. We provide opportunities for professional development, mentorship, and hands-on experience in cutting-edge financial practices and strategies.
                        </p>
                        <a href="project-details.html" className="link-btn">
                            <span className="link-effect">
                                <span className="effect-1">LEARN MORE</span>
                                <span className="effect-1">LEARN MORE</span>
                            </span>
                            <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                        </a>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6">
                    <div className="feature-card">
                        <div className="feature-card-icon">
                            <img src="/img/icon/feature-icon1-4.svg" alt="icon" />
                        </div>
                        <h4 className="feature-card-title">
                            <a href="project.html">Impactful Work</a>
                        </h4>
                        <p className="feature-card-text" style={{ minHeight: '115px' }}>
                            Join us and be part of a team that makes a tangible difference. Our work involves shaping the future of promising businesses and driving industry transformation.
                        </p>
                        <a href="project-details.html" className="link-btn">
                            <span className="link-effect">
                                <span className="effect-1">LEARN MORE</span>
                                <span className="effect-1">LEARN MORE</span>
                            </span>
                            <img src="/img/icon/arrow-left-top.svg" alt="icon" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CareersCardsSection;