import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setHeaderClass } from "../store/slices/general-slice";
import ContactHeroBanner from "../components/ContactHeroBanner";
import ContactAddressSection from "../components/ContactAddressSection";
import ContactMapSection from "../components/ContactMapSection";

declare var $;

const Contact = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        if ($("[data-bg-src]").length > 0) {
            $("[data-bg-src]").each(function () {
                var src = $(this).attr("data-bg-src");
                $(this).css("background-image", "url(" + src + ")");
                $(this).removeAttr("data-bg-src").addClass("background-image");
            });
        }
        dispatch(setHeaderClass("header-layout3 bg-white"))
    }, [])

    return <>
        <ContactHeroBanner />
        <ContactAddressSection />
        <ContactMapSection />
    </>
}

export default Contact;